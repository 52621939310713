export const environment = {
  env: 'dev',
  firebase: {
    cloud_messaging_public_key:
      'BJ5EJB-e1FFkrbJ-n6fm4B_0LVXzXhloeG0wvfeoqPQBaXU813_VeVyfmbf9ua-YRRJrCzDmRPEuIUSj8BEa1_w',
  },
  googleMaps: {
    apiKey: 'AIzaSyCypUOivs72C-YFk9rw7xIx0wgxxpmLisk',
  },
  postHog: {
    apiKey: 'phc_yLok2qp7PFZZ4VmdLPhG2uy3wY4x8WK8FDZKWTWP5cv',
  },
  production: true,
  recaptcha: { siteKey: '6LdHWQYaAAAAAJZD2mb3BA04roaayePxRwjpX-w1' },
  segment: {
    apiKey: '520noaxd9f',
  },
  workos: { envId: '01F6JRYSHPRRYW31X2T7V6DV2W' },
  workplace: {
    app_id: 1526238874112437,
  },
};
